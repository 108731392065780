import React from "react"
import Layout from "../components/layout/layout"
import SEO from "../components/seo"

const IndexPage = (props) => (
	<Layout {...props}>
		<SEO title="Object Marketing" />
	</Layout>
)

export default IndexPage
