import React from "react";
import "./feature.scss"
import LayoutSidePad from "../layout/layout-parts/layout-side-pad";
import LayoutMaxWidth from "../layout/layout-parts/layout-max-width";

export default class Feature extends React.Component<{
  image: string
}> {

  render() {
    return (
      <div className='feature'>
        <LayoutSidePad>
          <LayoutMaxWidth>
            <div className='feature-image-wrapper'>
              <div className='feature-image' style={{
                backgroundImage: `url(${this.props.image})`
              }}></div>
            </div>
            <div className='feature-text'>
              {this.props.children}
            </div>
          </LayoutMaxWidth>
        </LayoutSidePad>

      </div>
    );
  }

};



// SideBySide.defaultProps = {
//   lang: `en`,
//   meta: [],
//   description: ``,
// }

// SideBySide.propTypes = {
//   children: PropTypes.node.isRequired,
// }

// export default SideBySide
