import React from "react";
import "./menu.scss"
import LayoutMaxWidth from "../layout/layout-parts/layout-max-width";
// import LayoutSidePad from "../layout/layout-parts/layout-side-pad";

const Menu = () => (
  <div className='menu background-color-black'>
    {/* <LayoutSidePad> */}
      {/* <LayoutMaxWidth> */}
        <div className='menu-lists'>
          <ul className="menu-links">
            <li className="background-color-green"><a href="#services">Services</a></li>
            <li className="background-color-yellow"><a href="#clients">Clients</a></li>
            <li className="background-color-red"><a href="#work">Work</a></li>
            <li className="background-color-blue"><a href="#contact">Contact</a></li>
          </ul>
          <ul className="menu-contact">
            <li><a href="tel:+442087629292">+44 (0)20 8762 9292</a></li>
            <li><a href="https://goo.gl/maps/FMMVPTKEEmbDMZF39" target="_blank">Map</a></li>
          </ul>
        </div>
      {/* </LayoutMaxWidth> */}
    {/* </LayoutSidePad> */}
  </div>
)

export default Menu
