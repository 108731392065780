import React from "react";
import "./side-by-side.scss"
import { Colour } from "../../enums/colour";

// export default SideBySide = (contentSide: 'left' | 'right', { children }) => {

export default class SideBySide extends React.Component<{
  image: string,
  contentSide: 'left' | 'right'
  contentBgColor?: Colour
}> {
  

  private renderImage = () => {
    return (
      <div className='side-by-side-image' style={{
        backgroundImage: `url(${this.props.image})` 
      }}>
      </div>
    );
  };

  private renderText = () => {
    return (
      <div className={`side-by-side-text ${(this.props.contentBgColor ? `background-color-${this.props.contentBgColor}` : '')}`}>
        <div className='side-by-side-text-pad'>
         {this.props.children}
        </div>
      </div>
    );
  };

  render() {

    return (
      <div className='side-by-side'>
       
        {(this.props.contentSide === 'left') ? this.renderText() : this.renderImage()}

        {(this.props.contentSide === 'right') ? this.renderText() : this.renderImage()}

      </div>
    );
  }

};