import React from "react"
import PropTypes from "prop-types"

import "./layout-max-width.scss"

const LayoutMaxWidth = ({ children }) => {
  return (
    <div className='layout-max-width'>{children}</div>
  )
}

LayoutMaxWidth.propTypes = {
  children: PropTypes.node.isRequired,
}

export default LayoutMaxWidth
