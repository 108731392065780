import React from "react";
import "./banner.scss"
import { Colour } from "../../enums/colour";
import LayoutSidePad from "../layout/layout-parts/layout-side-pad";
import LayoutMaxWidth from "../layout/layout-parts/layout-max-width";

export default class Banner extends React.Component<{
  textColor?: Colour,
  bgColor?: Colour
}> {

  render() {
    return (
      <div className={`banner ${(this.props.textColor ? `color-${this.props.textColor}` : '')} ${(this.props.bgColor ? `background-color-${this.props.bgColor}` : '')}`}>
        <LayoutSidePad>
          <LayoutMaxWidth>
            <div className='banner-content'>
              {this.props.children}
            </div>
          </LayoutMaxWidth>
        </LayoutSidePad>
      </div>
    );
  }

};



// SideBySide.defaultProps = {
//   lang: `en`,
//   meta: [],
//   description: ``,
// }

// SideBySide.propTypes = {
//   children: PropTypes.node.isRequired,
// }

// export default SideBySide
