import PropTypes from "prop-types";
import React from "react";
import ObjectMarketingLogo from "../../images/object-marketing-logo@3x.png";
import "./header.scss"

const Header = () => (
  <header>
    <h1>
      <img src={ObjectMarketingLogo} alt="Object Marketing logo" />
    </h1>
  </header>
)

export default Header
