import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel as ReactResponsiveCarousel } from 'react-responsive-carousel';
import "./custom-carousel.scss";
import CarouselSlide01 from "../../images/carousel-slide-01.png";
import CarouselSlide02 from "../../images/carousel-slide-02.png";
import CarouselSlide03 from "../../images/carousel-slide-03.png";
import CarouselSlide04 from "../../images/carousel-slide-04.png";
import CarouselSlide05 from "../../images/carousel-slide-05.png";
import CarouselSlide06 from "../../images/carousel-slide-06.png";
import CarouselSlide07 from "../../images/carousel-slide-07.png";
import CarouselSlide08 from "../../images/carousel-slide-08.png";

export default class CustomCarousel extends React.Component {

  render() {
    return (
      <div className='custom-carousel'>
        <h3 className='color-red'>Some examples</h3>
        <div className='react-responsive-carousel'>
          <ReactResponsiveCarousel showStatus={false} showThumbs={false} infiniteLoop={true} autoPlay={true} interval={4000} transitionTime={500}>
            <div className='carousel-slide'>
              <img className="carousel-image" src={CarouselSlide01} />
              <div className="carousel-text">Event &amp; exhibition design</div>
            </div>
            <div className='carousel-slide'>
              <img className="carousel-image" src={CarouselSlide02} />
              <div className="carousel-text">Direct mail</div>
            </div>
            <div className='carousel-slide'>
              <img className="carousel-image" src={CarouselSlide03} />
              <div className="carousel-text">Sales promotions</div>
            </div>
            <div className='carousel-slide'>
              <img className="carousel-image" src={CarouselSlide04} />
              <div className="carousel-text">Corporate identity</div>
            </div>
            <div className='carousel-slide'>
              <img className="carousel-image" src={CarouselSlide05} />
              <div className="carousel-text">eBooks and brochures</div>
            </div>
            <div className='carousel-slide'>
              <img className="carousel-image" src={CarouselSlide06} />
              <div className="carousel-text">Emails &amp; online news</div>
            </div>
            <div className='carousel-slide'>
              <img className="carousel-image" src={CarouselSlide07} />
              <div className="carousel-text">Corporate literature</div>
            </div>
            <div className='carousel-slide'>
              <img className="carousel-image" src={CarouselSlide08} />
              <div className="carousel-text">Advertising (on and offline)</div>
            </div>
          </ReactResponsiveCarousel>
        </div>
      </div>
    );
  }

};



// SideBySide.defaultProps = {
//   lang: `en`,
//   meta: [],
//   description: ``,
// }

// SideBySide.propTypes = {
//   children: PropTypes.node.isRequired,
// }

// export default SideBySide
