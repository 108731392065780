import React, { ChangeEvent } from "react";
import "./contact.scss"

export default class Contact extends React.Component<{
  postToUrl: string;
}, {
  contactName: string;
  contactEmail: string;
  contactMessage: string;
}> {

  private formIsDisabled = () => {
    return !this.state || !this.state.contactName || !this.state.contactEmail || !this.state.contactMessage
  } 

  
  private formReturnUrl = () => {
    const url = this.props.postToUrl + '?message-sent=true';
    return url;
  } 

  render() {
    return (
      <div className='contact background-color-yellow'>
        <h3>Contact</h3>
        <div className='display-flex'>
          <div className='contact-details'>
            <p><span className='bold'>Object Marketing Ltd</span><br />Building 3, Chiswick Park<br />566 Chiswick High Road<br />London W4 5YA, UK</p>
            <p><a href="mailto:contact@objectmarketing.com">contact@objectmarketing.com</a></p>
            <p><a href="tel:+442087629292"></a>+44 (0)20 8762 9292</p>
          </div>
          <div className='contact-form'>

            <p>You can send a message via the form below.</p>
            <p className='small'>'Name', 'Email' and 'Message' are required.</p>
            <form action="https://formsubmit.co/fd7f0705b2ab0d141bebc5a574de2f20" method="POST">

              {/* fd7f0705b2ab0d141bebc5a574de2f20 = test@madebyew.com */}
              <input type="hidden" name="_next" value={this.formReturnUrl()} />
              <input type="hidden" name="_subject" value="New mewssage via objectmarketing.com" />
              <input type="hidden" name="_captcha" value="false" />
              <input type="text" name="_honey" style={{ display: 'none' }} />

              <div className='contact-form-row-double'>
                <div className='contact-form-input'>
                  <input type='text' name="name" placeholder='Name' onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    this.setState({
                      contactName: event.target.value
                    })
                  }} />
                </div>
                <div className='contact-form-input'>
                  <input type='text' name="email" placeholder='Email' onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    this.setState({
                      contactEmail: event.target.value
                    }) 
                  }} />
                </div>
              </div>

              <div className='contact-form-row-double'>
                <div className='contact-form-input'>
                  <input type='text' name="phone" placeholder='Phone' />
                </div>
                <div className='contact-form-input'>
                  <input type='text' name="address" placeholder='Address' />
                </div>
              </div>

              <div className='contact-form-row-single'>
                <div className='contact-form-input'>
                  <input type='text' name="subject" placeholder='Subject' />
                </div>
              </div>

              <div className='contact-form-row-single'>
                <div className='contact-form-input'>
                  <textarea name="message" placeholder='Type your message here' onChange={(event: ChangeEvent<HTMLTextAreaElement>) => {
                    this.setState({
                      contactMessage: event.target.value
                    }) 
                  }} ></textarea>
                </div>
              </div>

              <div className='contact-form-row-single'>
                <div className='contact-form-input'>
                  <input type='submit' disabled={this.formIsDisabled()} />
                </div>
              </div>

            </form>

          </div>
        </div>
      </div>
    );
  }

};
