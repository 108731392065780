import React from "react";
import PropTypes from "prop-types";
import Header from "../header/header";
import "./layout.scss";
import LayoutSidePad from "./layout-parts/layout-side-pad";
import Menu from "../menu/menu";
import SideBySide from "../side-by-side/side-by-side";
import ShowcaseTile01 from "../../images/showcase-tile-01.jpg";
import ShowcaseTile02 from "../../images/showcase-tile-02.jpg";
import ShowcaseTile03 from "../../images/showcase-tile-03.jpg";
import Poster01 from "../../images/poster-01.jpg";
import ClientLogos from "../../images/client-logos.png";
import Feature01 from "../../images/feature-01.jpg";
import { Colour } from "../../enums/colour";
import Banner from "../banner/banner";
import Feature from "../feature/feature";
import Poster from "../poster/poster";
import CustomCarousel from "../custom-carousel/custom-carousel";
import Contact from "../contact/contact";
import Footer from "../footer/footer";
import Growl from "../growl/growl";

const Layout = (props) => {

  // Get URL Origin for Contact Form Post
  const urlOrigin = props && props.location && props.location.origin;

  // Get 'message-sent' param to toggle message
  const urlSearch = props && props.location && props.location.search;
  const urlParams = new URLSearchParams(urlSearch);
  const urlParamMessageSent = urlParams.get('message-sent');
  console.log('sent', urlParamMessageSent);

  return (
    <>

      {/* {(urlParamMessageSent === 'true') && (
        <Growl message='Message sent!' />
      )} */}

      {/* Header */}
      <LayoutSidePad>
        {/* <LayoutMaxWidth> */}
        <Header />
        {/* </LayoutMaxWidth> */}
      </LayoutSidePad>

      {/* Menu */}
      <Menu />

      {/* Side by Side */}
      <SideBySide contentSide='right' image={ShowcaseTile01}>
        <h2 className={`color-${Colour.Yellow}`}>Welcome</h2>
        <p>Object is renowned as a leading UK tech, finance and innovation marketing agency made up of creative, hard-working and award-winning people. We offer impartial strategic advice and implementation support with fresh creativity and plenty of cross-industry experience.</p>
      </SideBySide>

      <Banner textColor={Colour.White} bgColor={Colour.Black}>
        “We see Object Marketing as a key part of our team, a valuable extension to our sales and marketing department. They are always there to help, and always focused on our needs. Very Refreshing.”
      </Banner>

      {/* Side by Side */}
      <SideBySide contentSide='left' contentBgColor={Colour.Yellow} image={ShowcaseTile02}>
        <h2>Knowledge</h2>
        <p>Having worked for many world-leading and start-up tech, finance and consumer brands we have deep knowledge of many industries including IT, financial services, manufacturing, retailing, gaming, FMCG, transport and the public sector.</p>
      </SideBySide>

      <Feature image={Feature01}>
        <div>
          Object&rsquo;s defining features are our small agency care with large agency knowledge, a truly client-centric nature, and a laser focus on improving sales or share of voice.
        </div>
      </Feature>

      <Banner textColor={Colour.White} bgColor={Colour.Red}>
        “You did a great job. All feedback is positive. Your pro-activity in these challenging periods is really appreciated and your ideas are good. Well done. Thank you for your contribution to our success.”
      </Banner>

      {/* Side by Side */}
      <SideBySide contentSide='right' image={ShowcaseTile03} contentBgColor={Colour.Yellow}>
        <h2 id='services' className={`color-${Colour.Red} center-to-left-sm`}>Services</h2>
        <div className='display-flex'>
          <ul className='width-50pt-sm list-style-type-none font-size-small'>
            <li>Marketing Strategy</li>
            <li>Corporate Writing</li>
            <li>Sales Promotion</li>
            <li>In &amp; Outdoor Advertising</li>
            <li>Events &amp; Stunts</li>
          </ul>
          <ul className='width-50pt-sm list-style-type-none font-size-small'>
            <li>Branding &amp; Graphic Design</li>
            <li>Market Research &amp; Surveys</li>
            <li>Omnichannel Direct Marketing</li>
            <li>Social &amp; online campaigns</li>
            <li>Media selection &amp; buying</li>
          </ul>
        </div>
      </SideBySide>

      <Banner textColor={Colour.Black} bgColor={Colour.White}>
        “This is really great, I think we are doing very well – thanks again for all your efforts – they are really working.”
      </Banner>

      <Poster image={Poster01} />

      <div id='clients'>

        <img src={ClientLogos} />

        {/* 
        <Tiles images={[
          ClientLogo01,
          ClientLogo02,
          ClientLogo03,
          ClientLogo04,
          ClientLogo05,
          ClientLogo06,
          ClientLogo07,
          ClientLogo08
        ]} /> 
        */}

      </div>

      <Banner textColor={Colour.Black} bgColor={Colour.Yellow}>
        “A brilliant creative solution in a really tight time-frame. Great Work!”
      </Banner>

      <div id='work'>
        <CustomCarousel />
      </div>

      <div id='contact'>
        <Contact postToUrl={urlOrigin} />
      </div>

      <Footer />

    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
