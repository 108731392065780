import React from "react"
import PropTypes from "prop-types"

import "./layout-side-pad.scss"

const LayoutSidePad = ({ children }) => {
  return (
    <div className='layout-side-pad'>{children}</div>
  )
}

LayoutSidePad.propTypes = {
  children: PropTypes.node.isRequired,
}

export default LayoutSidePad
