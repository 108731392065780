import React from "react";
import "./footer.scss"

const Footer = () => (
  <footer>
    <p>+44 (0)20 8762 9292</p>
    <div>&copy; {new Date().getFullYear()} Object Marketing Ltd.</div>
  </footer>
)

export default Footer
