import React from "react";
import "./poster.scss"

export default class Poster extends React.Component<{
  image: string
}> {
  
  render() {

    return (
      <div className='poster' style={{
        backgroundImage: `url(${this.props.image})` 
      }}></div>
    );
  }

};



// SideBySide.defaultProps = {
//   lang: `en`,
//   meta: [],
//   description: ``,
// }

// SideBySide.propTypes = {
//   children: PropTypes.node.isRequired,
// }

// export default SideBySide
